.caption-panel
{
	display: block;
	overflow: hidden;
	position: relative;
	z-index: 0;

	&__heading 	
	{
		background-color: rgba($black, 0.6);
		bottom: 0;
		color: $smoke;
		line-height: 1.4;
		margin: 0;
		padding: rem-calc(5 10);
		position: absolute;
		width: 100%;
		z-index: z-index(3);

		&__title 
		{
			color: $smoke;
			margin-bottom: 0.25rem;

			&__section 
			{
				color: $white;
				display: inline-block;
				font-size: 1.2rem;
				margin-right: 0.5rem;
				padding: 0.10rem 0.375rem;
				position: relative;
				top: rem-calc(-3);

				&--courses 
				{
					background-color: $courses-color-standard;
				}

				&--equipment 
				{
					background-color: $equipment-color-standard;
				}

				&--tips 
				{
					background-color: $tips-and-tuition-color-standard;
				}
			}
		}

		&__info 
		{
			font-size: 0.8rem;
			line-height: 1;
		}

		&--courses 
		{
			background-color: rgba($courses-color-standard, 1);
		}

		&--tips 
		{
			background-color: rgba($tips-and-tuition-color-standard, 1);
		}

		&--equipment
		{
			background-color: rgba($equipment-color-standard, 1);
		}

		&--news 
		{
			background-color: rgba($news-color-standard, 1);
		}

		&--large 
		{
			padding: rem-calc(5 10);
		}
	}

	&__image 
	{
		width: 100%;
	}
}
