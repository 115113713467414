.more-menu
{
	background-color: $white;
	padding: 0.5rem 1rem;

	.more-menu
	{		
		&__list
		{
			float: left;
			font-size: 0.9rem;
			list-style: none;
			margin: 0;
			padding: 0;
		}
		
		&__item
		{
			border-left: rem-calc(1) solid $iron;
			display: table-cell;
			padding: 0 1rem;
		}

		&__link
		{			
			color: $black;

			&:hover
			{
				color: $navigation-hover-color;
			}

			&--heading
			{
				display: block;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		&__sub-list
		{
			font-size: 0.9rem;
			list-style: none;
			margin: 0;
			padding: 0;

			&__item
			{
				display: block;
			}
		}
	}
}
