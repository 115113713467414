.wp-block-table
{
    font-family: $table-font;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin: 0 0 1rem;
    overflow-x: scroll;
    
    table
    {
        border-collapse: separate;
        margin: 0 auto;
    }

    td, th
    {
        border-right: 1px solid white;
        padding: 0.25rem 0.4rem;
        text-align: center;

        &:last-child
        {
            border-right: none;
        }
    }
    
    tbody
    {
        tr
        {
            &:nth-child(odd)
            {
                background-color: $table-odd-row-color;
            }
        }
    }

    thead
    {
        color: white;

        th
        {
            background-color: black;
        }
    }

    tfoot
    {
        color: $table-footer-text-color;

        tr
        {
            background-color: $table-footer-row-color;
        }
    }
}

@media #{$medium-up}
{
    .wp-block-table
    {
        overflow-x: initial;
    }
}