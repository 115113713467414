.site-body
{
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;

	@media #{$xxlarge-up}
	{
		min-height: 100vh; // make sure pages with very small amounts of content aren't too short in size XXL (which breaks the sidebar sticky menu)
		vertical-align: top;
	}
}
