.featured-videos
{
	&__video 
	{
		> a 
		{
			display: block;

			img 
			{
				width: 100%;
			}
		}

		&__video-overlay
		{		   
			background: rgba(0, 0, 0, 0.65);
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&__video-overlay-icon
		{
			color: $white;
			content: ' ';
			font-size: 10rem;
			left: 50%;
			opacity: 0.75;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.fi-play-video:before
		{
			display: block;
			line-height: 0.6;
		}
	}
}
