.custom-button
{
	&--2-fore-1
	{
		background-color: $two-fore-one-color;
		margin-bottom: 1rem;
		padding: 0.75rem 1.25rem;

		&:hover
		{
			background-color: darken($two-fore-one-color, 10%);
		}
	}

	&--center-horizontally 
	{
		@include center-horizontally;
	}

	&--courses 
	{
		background-color: $courses-color-standard;
	}

	&--tips 
	{
		background-color: $tips-and-tuition-color-standard;
	}

	&--equipment 
	{
		background-color: $equipment-color-standard;
	}

	&--news 
	{
		background-color: $news-color-standard;
	}

	&--full-width 
	{
		display: block;
	}
}
