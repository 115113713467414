.grid-panel
{
	background: $panel-background;
	border: 1px solid $panel-border-color;
	padding: rem-calc(10);

	&--no-border 
	{
		border: 0;
	}
}
