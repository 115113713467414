.site-content
{
	background-color: $white;
	display: table-cell;
	padding: 0 0 1rem 0;
	vertical-align: top;
	width: 100%;

	&__inner
	{
		padding-bottom: 2rem;

		figure.wp-block-image,
		.wp-block-image > figure
		{
			margin: 0 0 1.25rem;
		}
	}
}
