.desktop-sidebar
{
	cursor: default;
	display: none;

	.desktop-sidebar
	{
		&__inner
		{
			background-color: $white;
			display: block;

			.sidebarAdBlock 
			{
				display: none;
				height: 75rem;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				padding-top: 1rem;
				position: relative;
				width: 18.75rem;

				&__inner 
				{
					height: 37.5rem;

					.stickyTop 
					{
						position: fixed;
						top: 1rem;					
					}

					.mpu-ad:empty
					{
						background: $white;
					}

					.upward-unstuck
					{
						top: 1rem;
					}

					.downward-unstuck
					{
						top: 37.4rem;
					}
				}
			}
		}

		&__sticky-wrapper
		{
			&__inner
			{
				&--stuck
				{
					position: fixed;
					top: rem-calc($xlarge-header-height-value + $header-border-width-value + $rem-base-value + 1);

					.desktop-sidebar__sticky-wrapper__back-to-top
					{
						display: block;
					}
				}
			}

			&__back-to-top
			{
				background-color: $white-smoke;
				border: rem-calc(1) solid $gainsboro;
				border-radius: rem-calc(5);
				box-shadow: rem-calc(2 2 2) $gainsboro;
				cursor: pointer;
				display: none;
				line-height: 1.6;
				margin: 0 auto;
				padding: 0.5rem 1rem;
				width: rem-calc(150);

				&__text:before
				{
					@include icon($arrow-up-icon);
					margin-right: 1rem;
				}
			}
		}
	}

	@media #{$xlarge-up}
	{
		display: table-cell;
		vertical-align: top;
		width: rem-calc(316);
	}

	@media #{$xxlarge-up}
	{
		top: $large-header-height;
		
		.desktop-sidebar__sticky-wrapper
		{
			&__inner
			{
				&--stuck
				{
					top: rem-calc(16);
				}
			}
		}
	}
}
