.advert
{
	margin: auto;
	position: relative;

	&--shallow
	{
		margin-bottom: 1rem;
	}
}

.full-width-top-ad
{
	&--empty
	{
		height: 5rem;
	}
}

.top-ad
{
	height: rem-calc(50);
	margin: 0.5rem auto 1rem auto;
	max-width: rem-calc(320);	// small size will need to be able to shrink the banner to fit in screen
	overflow: hidden;

	&:empty
	{
		background-color: $white-smoke;
	}
}

.mpu-ad
{
	margin-bottom: 1rem;
	max-width: rem-calc(300);	// small size will need to be able to shrink the banner to fit in screen
	min-height: rem-calc(250);

	&:empty
	{
		background-color: $white-smoke;
	}
}

.footer-ad
{
	margin: 1rem;
}

.zero-height-ad
{
	height: 0;
}

@media #{$medium-up}
{
	.mpu-ad
	{
		width: rem-calc(300);
	}

	.top-ad
	{
		height: auto;
		min-height: rem-calc(90);
	}
}

@media #{$medium-only}
{
	.top-ad
	{
		max-width: rem-calc(728);
	}
}

@media #{$large-up}
{
	.top-ad
	{
		max-width: rem-calc(970);
	}
}
