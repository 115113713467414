﻿%collapsed-list
{
	list-style: none;
	margin: 0;
	padding: 0;
}

%inline-list
{
	@extend %collapsed-list;
	@include clearfix;

	> li
	{
		float: left;
	}
}

%stacked-list
{
	@extend %collapsed-list;

	> li
	{
		display: block;
	}
}

ol,
ul
{
	&.collapse
	{
		@extend %collapsed-list;
	}

	&.inline
	{
		@extend %inline-list;
	}

	&.stack
	{
		@extend %stacked-list;
	}

	&.square
	{
		list-style: square;
	}

	@media #{$large-up}
	{
		font-size: 0.95rem;
	}
}
