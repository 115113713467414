%horizontal-nav-link
{	
	color: $white;
	display: block;
	font-family: $menu-font, $body-font-family;
	font-size: 0.9rem;
}

.primary-nav-horizontal
{
	height: $large-header-height;
	overflow: hidden;
		
	.primary-nav-horizontal
	{
		&__menu
		{
			display: block;
			margin: 0;

			&__item
			{
				display: block;
				float: left;

				&.hover
				{
					&.primary-nav-horizontal__item--has-sub-menu:after
					{
						border-top-color: $white;
					}
				}
			}

			&__link
			{
				@extend %horizontal-nav-link;
				height: $large-header-height;
				padding: 1.4rem 1rem 0 1rem;
				text-transform: uppercase;
				
				&--has-sub-menu:after
				{
					@include arrow-down(6, $white);					
					float: right;
					margin: rem-calc(8 0 0 8);		
				}

				&:hover,
				&--active
				{
					background-color: $navigation-hover-color;
				}
			}
		}
		
		&__sub-menu
		{
			background-color: lighten($navigation-back-color, 10%);
			border-radius: rem-calc(0 2 2 0);
			box-shadow: rem-calc(2 2 2) $oil;
			display: none;
			margin: 0;
			position: absolute;
			z-index: z-index(90);

			&__item
			{
				border-bottom: rem-calc(1) solid $navigation-border-color;
				display: block;
			}
				
			&__link
			{
				@extend %horizontal-nav-link;
				height: rem-calc(35);
				padding: 0.5rem;

				&:hover
				{
					background-color: $navigation-hover-color;
				}
			}	
		}
	}

	&__more
	{
		display: none;
		float: right;

		&:hover
		{
			background-color: $navigation-hover-color;
		}

		&__link
		{
			@extend %horizontal-nav-link;
			height: $large-header-height;
			padding: 1.45rem 0.5rem 0 1rem;
			text-transform: uppercase;

			&:hover
			{
				color: $white;
			}

			&:after
			{
				@include arrow-down(6, $white);					
				float: right;
				margin: rem-calc(8 0 0 8);			
			}

			&:hover:after
			{
				border-top-color: $white;
			}

			&__text-expanded
			{
				display: none;
			}
		}
	}
}
