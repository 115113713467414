h1, 
h2, 
h3,
h4, 
h5, 
h6
{
	font-family: $main-heading-font, $body-font-family;
	font-size: $heading-Size;	
	letter-spacing: -0.05rem;
	line-height: 1.1;
}

h1
{	
	margin: 0 0 0.5rem;
}

h2
{
	font-size: $heading-Size * 0.8;
}

h3
{
	font-size: $heading-Size * 0.7;
}
	
h4, 
h5, 
h6
{
	font-size: $heading-Size * 0.55;
}
