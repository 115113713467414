/* latin-ext */
@font-face {
	font-display: optional;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'),
		 local('Roboto-Regular'),
		 url($font-host-name + '/fonts/roboto/regular/latin-ext/roboto-latin-ext.woff2') format('woff2');
}

/* latin */
@font-face {
	font-display: optional;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'),
		 local('Roboto-Regular'),
		 url($font-host-name + '/fonts/roboto/regular/latin/roboto-latin.woff2') format('woff2');
}

/* latin-ext */
@font-face {
	font-display: optional;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'),
		 local('Roboto-Medium'),
		 url($font-host-name + '/fonts/roboto/medium/latin-ext/roboto-latin-ext.woff2') format('woff2');
}

/* latin */
@font-face {
	font-display: optional;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'),
		 local('Roboto-Medium'),
		 url($font-host-name + '/fonts/roboto/medium/latin/roboto-latin.woff2') format('woff2');
}

/* latin-ext */
@font-face {
	font-display: optional;
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Condensed'),
		 local('RobotoCondensed-Regular'),
		 url($font-host-name + '/fonts/roboto_condensed/regular/latin-ext/roboto-condensed-v25-latin-ext-regular.woff2') format('woff2');
}

/* latin */
@font-face {
	font-display: optional;
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Condensed'),
		 local('RobotoCondensed-Regular'),
		 url($font-host-name + '/fonts/roboto_condensed/regular/latin/roboto-condensed-v25-latin-regular.woff2') format('woff2');
}
