.product-card
{
	&__arrow__head__icon
	{
		background-color: $black !important;
	}

	&__arrow__tail
	{
		background-color: $black !important;
	}

	&__price-action
	{
		&__link
		{
			background-color: $equipment-color-standard !important;

			&:hover
			{
				background-color: $product-card-button-hover-color !important;
			}

			&__content
			{
				&__text
				{
					color: $black !important;
				}
			}

		}
	}

	&__tab-control
	{
		&__tabs
		{
			&__tab-name
			{
				&.product-card__tab-control__tabs__tab-name--active
				{
					background-color: $equipment-color-standard !important;
					color: $black !important;
				}
			}
		}

		&__tab-content
		{
			border-top:  rem-calc(2) solid $equipment-color-standard !important;
		}
	}

	&__rating-stars
	{
		svg
		{
			path
			{
				&:nth-of-type(2)
				{
					stroke: $equipment-color-standard; // Use the $equipment-color-standard variable for stroke color
				}
			}
		}

		.star-background
		{
			stop-color: $equipment-color-standard !important;
		}

		.star-foreground
		{
			stop-color: $white !important;
		}
	}
}
