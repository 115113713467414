// Styles for full-skin takeover ads

@media (min-width: 1100px)
{
	.takeover
	{
		max-width: rem-calc(1400);
		margin: 0 auto;

		.site-header 
		{
			position: relative;
		}

		@media #{$xlarge-only}
		{
			.site-body
			{
				padding-top: 0;
			}
		}
	}
}

