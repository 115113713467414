.site-header
{
	background-color: $header-background-color;
	border-bottom: $header-border-width solid $header-bottom-border-color;

	@media #{$xlarge-up}
	{
		position: fixed;
		top: 0;
		width: 100%;
		z-index: z-index(90);
	}
}
