.section-tag 
{
	color: $white;
	font-size: 0.8rem;
	left: 0;
	padding: 0.25rem 0.5rem;
	position: absolute;
	text-align: left;
	top: 0;
	z-index: z-index(1);

	&--equipment 
	{
		background-color: $equipment-color-standard;
	}

	&--news
	{
		background-color: $news-color-standard;
	}

	&--courses
	{
		background-color: $courses-color-standard;
	}

	&--tips
	{
		background-color: $tips-and-tuition-color-standard;
	}

	&--videos 
	{
		background-color: $videos-color-standard;

	}
}
