.floated-ad
{
	@media #{$large-up}
	{
		margin: rem-calc(20);

		&--left 
		{
			float: left;
			margin-left: 0;
		}

		&--right
		{
			float: right;
			margin-right: 0;
		}
	}
}
