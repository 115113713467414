.primary-nav-mobile
{
	.primary-nav-mobile
	{
		&__menu
		{
			&__link
			{
				background-color: $tuatara;
				font-size: 0.9rem;
				font-weight: bold;
				padding: 0.5rem 1rem;
				text-transform: uppercase;
			}

			&__sub-link
			{
				font-size: 0.8rem;
				padding: 0.4rem 1rem;
			}
		}
	}
}
