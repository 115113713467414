.font-large, 
%font-large
{
	font-size: 1.5rem;
}

.font-small, 
%font-small
{
	font-size: 0.9rem;
}

.font-xsmall, 
%font-xsmall
{
	font-size: 0.8rem;
}

.font-xxsmall,
%font-xxsmall
{
	font-size: 0.7rem;
}
