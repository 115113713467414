.button
{
	&--full-width
	{
		width: 100%;
	}

	&__icon
	{
		margin-right: 1rem;

		&--right
		{
			margin-left: 1rem;
			margin-right: 0;
		}
	}
}
