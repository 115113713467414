.trending-bar 
{
	background-color: $white-smoke;
	margin-bottom: rem-calc(20);
	padding: 0.5rem;

	.trending-bar 
	{
		&__heading 
		{
			color: $pros-color;
			font-size: 1.2rem;
			line-height: 1.65;
			margin: 0 0 rem-calc(10) rem-calc(15);
			text-transform: uppercase;
		}

		&__list 
		{
			line-height: 2.5;
			list-style-type: none;
			margin-bottom: 0;
			overflow: hidden;

			&__item 
			{
				float: left;
				font-size: 0.8rem;
				font-weight: bold;
				width: 100%;

				&__content 
				{
					display: inline-block;
				}
			}
		}
	}

	@media #{$medium-up}
	{
		.trending-bar 
		{
			&__list 
			{
				&__item 
				{
					margin-right: rem-calc(50);
					width: auto;
				}
			}
		}
	}

	@media #{$large-up}
	{
		.trending-bar 
		{
			&__heading 
			{
				float: left;
				margin: 0 rem-calc(30) 0 rem-calc(10);
			}

			&__list 
			{
				margin-left: 0;
			}
		}
	}
}
