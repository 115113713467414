.latest-stories 
{
	.latest-stories 
	{
		&__item 
		{
			display: block;
			margin-bottom: 1.2rem;

			&__heading 
			{
				font-size: 1.4rem;
				line-height: 1;
			}
		}
	}
}

@media #{$xxlarge-up}
{
	.latest-stories 
	{
		.latest-stories 
		{
			&__item 
			{
				&__heading 
				{
					font-size: 1.6rem;
				}
			}
		}
	}
}

