@media #{$large-up}
{
	.off-canvas-wrap
	{
		&.move-left,
		&.move-right
		{
			.inner-wrap
			{
				transform: none !important;
			}

			.exit-off-canvas
			{
				display: none !important;
			}
		}
	}

	.off-canvas-menu-mobile
	{
		display: none !important;
	}
}
