.our-magazines 
{
	.our-magazines 
	{
		&__heading 
		{
			margin-bottom: rem-calc(15);
		}

		&__description 
		{
			color: $body-copy-color;
			margin-top: rem-calc(15);
		}
	}
}
