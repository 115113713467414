.article-list
{
	&__list 
	{
		list-style-type: none;

		> li 
		{
			margin-bottom: 1rem;
		}

		&__article 
		{
			position: relative;

			img 
			{
				display: block;
				width: 100%;
			}

			&__heading 
			{
				color: $primary-color;
			}

			&__meta 
			{
				color: $aluminum;
				font-style: italic;
				margin-bottom: 0.3rem;

				&__pubdate 
				{
					color: $charcoal;
				}

				&__author 
				{
					color: $black;
					font-weight: bold;
				}
			}

			&__snippet 
			{
				color: $charcoal;
				margin-top: 0.5rem;

				:last-child 
				{
					margin-bottom: 0;
				}
			}

			&__tag 
			{
				color: $white;
				margin-bottom: rem-calc(5);
				padding: 0.5rem 1rem;
				text-align: left;
				width: 100%;

				&--equipment 
				{
					background-color: $equipment-color-standard;
				}

				&--news
				{
					background-color: $news-color-standard;
				}

				&--courses
				{
					background-color: $courses-color-standard;
				}

				&--tips
				{
					background-color: $tips-and-tuition-color-standard;
				}

			}
		}
	}

	&--promo 
	{
		padding-top: 1.5rem;
	}

	@media #{$medium-up}
	{
		.article-list
		{
			&__article
			{
				&__tag 
				{
					margin-bottom: 0;
					position: absolute;
					right: 0;
					top: 0;
					width: auto;
				}
			}
		}
	}

}
