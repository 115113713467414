﻿.site-breadcrumbs
{
	margin: 1rem 0;

	@media #{$small-only}, #{$medium-only}
	{
		.site-breadcrumbs
		{
			&__item
			{
				display: none;

				&--previous
				{
					display: block;
					font-size: 0.9rem;
					text-transform: none;

					&:before
					{
						@include icon($arrow-left-icon);
						color: $primary-color;
						font-size: 1.2rem;
						margin-left: 0;
						margin-right: 0.5rem;
						top: 0;
						vertical-align: middle;
					}
				}
			}
		}
	}

	@media #{$large-up}
	{
		margin-top: 0;

		.site-breadcrumbs
		{
			&__item
			{		
				&--unavailable
				{
					color: $aluminum;
					cursor: default;
				}
					 		
				&__back-text
				{
					display: none;
				}
			}
		}
	}	
}
