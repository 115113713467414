.footer-nav
{
	.footer-nav
	{
		&__list
		{
			line-height: 1;
			margin: 0 0 1rem 0;
		}

		&__item
		{
			display: inline-block;
			margin-right: 0.5rem;
		}

		&__link
		{
			color: $white;
			font-size: 0.75rem;
			font-weight: bold;
			text-transform: uppercase;

			&:hover
			{
				color: $footer-link-hover-color;
			}
		}
	}
}
