.content-panel
{
	margin-bottom: 1.5rem;
	position: relative;

	&__heading
	{
		background-color: $white-smoke;
		margin: 0;
		padding: 1rem;

		&--equipment
		{
			border-bottom: rem-calc(8) solid $equipment-color-standard;
		}

		&--course
		{
			border-bottom: rem-calc(8) solid $courses-color-standard;
		}

		&--tips
		{
			border-bottom: rem-calc(8) solid $tips-and-tuition-color-standard;
		}

		&--news 
		{
			border-bottom: rem-calc(8) solid $news-color-standard;
		}

		&--main-brand 
		{
			background-color: $main-brand-color;
			color: $white;
		}
	}

	&__content
	{
		padding: 1rem 0.5rem 0.5rem 0.5rem;
	}
}
