.site-header-mobile
{
	@include gradient($header-color-vdark, $header-color-dark, $header-color-standard);
	border-bottom: 1px solid $white;
	box-sizing: content-box;
		
	.site-header-mobile
	{
		&__title
		{
			background: url('../images/logos/todays.golfer.100x34.png') no-repeat center center;
			display: block;
			text-indent: rem-calc(-999999);
		}

		&__icon
		{
			color: $white;
			display: block;
			font-size: 2rem;
			text-align: center;

			&:hover
			{
				background-color: $navigation-hover-color;
			}
		}
	}
}

%close-icon
{
	font-size: 2rem;
	line-height: 2.7rem;
	text-align: center;
	text-indent: 0;

	&:before
	{
		content: $cross-icon;
		font-family: $icon-font;
	}
}

.off-canvas-wrap.move-left
{
	.site-header-mobile__icon
	{
		&--menu
		{
			@extend %close-icon;

			.site-header-mobile__icon__text
			{
				display: none;
			}
		}
	}
}

.off-canvas-wrap.move-right
{
	background-color: $oil;

	.site-header-mobile__icon
	{
		&--home
		{
			@extend %close-icon;

			.site-header-mobile__icon__text
			{
				display: none;
			}
		}
	}
}
