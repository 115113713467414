.top-ad-wrapper
{
	display: flex;
	min-height: rem-calc(132);	
}

.top-ad-container
{
	margin: auto;
	z-index: z-index(3);

	&--closed 
	{
		display: none;
	}

	&__close 
	{
		color: $black;
		display: none;
		font-size: 1.5rem;
		position: absolute;
		right: 0.5rem;
		z-index: z-index(50);

		&--closeable 
		{
			display: block;
		}
	}

	&__sticky-container
	{
		&--stuck
		{
			> :first-child
			{
				left: 50%;
				position: fixed;
				transform: translate(-50%);
				top: 0;
			}
		}
	}

	&__top-ad
	{
		min-height: rem-calc(1); // The ad container requires a height > 0 to be classed as visible for the purposes of assigning an id
	}

	&.stickyBottom
	{
		z-index: z-index(50);
	}

	&.stickyTop
	{
		z-index: z-index(50);
	}
}

@media #{$large-up}
{	
	.top-ad-wrapper
	{
		min-height: rem-calc(282);
	}
}
