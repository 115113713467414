﻿.site-header-horizontal
{
	.site-header-horizontal
	{
		&__inner
		{
			@include gradient($header-color-vdark, $header-color-dark, $header-color-standard);
			border-bottom: rem-calc(1) solid $white;
		}
		
		&__account 
		{
			@extend %font-xsmall;
			color: $white;
			display: block;
			float: right;
			padding: 0.5rem;

			&__link
			{
				color: $white;
				display: inline-block;

				&:hover
				{
					text-decoration: underline;
				}
			}
		}

		&__logo
		{
			float: left;
			width: rem-calc(165);

			&__link
			{
				background: url('../images/logos/todays.golfer.100x34.png') no-repeat rem-calc(55) center;
				color: $white;
				display: block;
				height: $large-header-height;

				&__icon
				{
					display: inline-block;
					font-size: 2rem;
					height: $large-header-height;
					line-height: $large-header-height;
					text-align: center;
					width: $large-header-height;
				}
			}
		}
	}
}
