
@mixin arrow($direction, $size, $color) 
{
	content: '';
	display: block;
	height: 0;
	width: 0;

	@if $direction == 'left'
	{
		border-bottom: rem-calc($size) solid transparent;
		border-right: rem-calc($size) solid $color;
		border-top: rem-calc($size) solid transparent;
	}
	@else if $direction == 'right'
	{
		border-bottom: rem-calc($size) solid transparent;
		border-left: rem-calc($size) solid $color;
		border-top: rem-calc($size) solid transparent;
	}
	@else if $direction == 'top'
	{
		border-bottom: rem-calc($size) solid $color;
		border-left: rem-calc($size) solid transparent;
		border-right: rem-calc($size) solid transparent;
	}
	@else if $direction == 'bottom'
	{
		border-left: rem-calc($size) solid transparent;
		border-right: rem-calc($size) solid transparent;
		border-top: rem-calc($size) solid $color;
	}	
	@else if $direction == 'top-left'
	{
		border-right: rem-calc($size) solid transparent;	
		border-top: rem-calc($size) solid $color;
	}
	@else if $direction == 'top-right'
	{
		border-left: rem-calc($size) solid transparent;
		border-top: rem-calc($size) solid $color;
	}
	@else if $direction == 'bottom-left'
	{
		border-bottom: rem-calc($size) solid $color;
		border-right: rem-calc($size) solid transparent;	
	}
	@else if $direction == 'bottom-right'
	{
		border-bottom: rem-calc($size) solid $color;
		border-left: rem-calc($size) solid transparent;
	}
}

@mixin arrow-right($size: 10, $color: $black)
{
	@include arrow('right', $size, $color);
}

@mixin arrow-down($size: 10, $color: $black)
{
	@include arrow('bottom', $size, $color);
}
