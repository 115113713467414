.screen-size-detector
{
	display: none;
	font-family: S;
	position: fixed;
	right: 0;
	top: 0;

	&:after
	{
		content: 'Small';
		font-family: sans-serif;
	}

	@media #{$medium-up}
	{
		font-family: M;

		&:after
		{
			content: 'Medium';
		}
	}

	@media #{$large-up}
	{
		font-family: L;

		&:after
		{
			content: 'Large';
		}
	}

	@media #{$xlarge-up}
	{
		font-family: XL;

		&:after
		{
			content: 'X-Large';
		}
	}

	@media #{$xxlarge-up}
	{
		font-family: XXL;

		&:after
		{
			content: 'XX-Large';
		}
	}
}


