.site-footer
{
	background: $oil;
	bottom: 0;
	color: $iron;
	cursor: default;
	padding: 1rem 0 6rem 0;
	position: relative;

	.site-footer
	{
		&__logo
		{
			float: left;
			margin: 0 1rem 1rem 0;
		}

		&__copyright
		{
			clear: both;
			color: $aluminum;
			font-size: 0.8rem;
			line-height: 1.2;
			margin: 0;
		}

		&__company-info
		{
			color: $aluminum;
			font-size: 0.7rem;
			line-height: 1.2;
			margin: 0.5rem 0 0 0;
		}

		&__social-links
		{
			margin: 0 auto 1rem auto;
			width: rem-calc(310);

			&__item
			{
				display: inline-block;
				margin-right: 0.5rem;
			}
		
			&__link
			{
				background-color: $steel;
				border-radius: rem-calc(25);
				color: $white-smoke;
				display: block;
				font-size: rem-calc(32);
				height: rem-calc(50);
				line-height: rem-calc(50);
				text-align: center;
				width: rem-calc(50);

				&:hover
				{
					background-color: lighten($steel, 10%);
					border: 0;
					transition: background-color 200ms;
				}

				&--facebook
				{
					background-color: $facebook-color;

					&:hover
					{
						background-color: lighten($facebook-color, 10%);
					}
				}

				&--twitter
				{
					background-color: $twitter-color;

					&:hover
					{
						background-color: lighten($twitter-color, 10%);
					}
				}

				&--google-plus
				{
					background-color: $google-plus-color;

					&:hover
					{
						background-color: lighten($google-plus-color, 10%);
					}
				}

				&--youtube
				{
					background-color: $youtube-color;

					&:hover
					{
						background-color: lighten($youtube-color, 10%);
					}
				}
			}			
		}
	}

	@media #{$large-only}
	{		
		.site-footer
		{
			&__social-links
			{
				width: rem-calc(250);

				&__item
				{
					margin-right: 0.2rem;
				}

				&__link
				{
					border-radius: rem-calc(20);
					font-size: rem-calc(24);
					height: rem-calc(40);
					line-height: rem-calc(40);
					width: rem-calc(40);
				}
			}
		}
	}

	@media #{$large-up}
	{
		.site-footer
		{
			padding: 1rem 0;
		}
	}

	@media #{$xxlarge-up}
	{
		bottom: 0;
		height: $xxlarge-footer-height;
		left: 0;
		width: 100%;

		.row
	    {
			margin-left: rem-calc(205);
			width: auto;
	    }
	}
}
