﻿@import 'foundation/functions';

// master page
$base-z-index: 1000;

// Colour Palettes

$main-brand-color: #24619F;

$body-copy-color: #222;

$header-color-vlight: #67C762;
$header-color-light: #3FA93A;
$header-color-standard: #2B75BC;
$header-color-dark: #215994;
$header-color-vdark: #13365C;

$equipment-color-standard: #FC9D2F;
$call-to-action-color: #FFC91D;
$news-color-standard: #2A7681;
$courses-color-standard: #98C454;
$tips-and-tuition-color-standard: #2BADE9;
$videos-color-standard: #639;

$pros-color: #60A842;
$cons-color: #D00000;

$facebook-color: #3B5998;
$twitter-color: #1DACED;
$google-plus-color: #E46044;
$youtube-color: #DB4A39;

$header-background-color: #434C52;
$header-bottom-border-color: #4CC746;

$navigation-back-color: $header-background-color;
$navigation-hover-color: $header-bottom-border-color;

$footer-link-hover-color: #EFD51F;

$navigation-border-color: #373D42;

$link-color: #3FA93A;

$two-fore-one-color: #98C454;

$table-odd-row-color: #B2B2B2;
$table-footer-row-color: #384047;
$table-footer-text-color: #70F000;

// Element Dimensions

$rem-base-value: 16;

$header-border-width-value: 8;
$header-border-width: rem-calc($header-border-width-value);

$large-header-height-value: 55;
$large-header-height: rem-calc($large-header-height-value);

$xlarge-header-height-value: 85;
$xlarge-header-height: rem-calc($xlarge-header-height-value);

$xxlarge-footer-height-value: 200;
$xxlarge-footer-height: rem-calc($xxlarge-footer-height-value);

// Font Stacks

$body-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$menu-font: 'Roboto', $body-font;
$main-heading-font: 'Roboto Condensed', $body-font;
$table-font: 'Roboto Condensed', $body-font;

// Font Sizes

$heading-Size: 2rem;

// Icons
$icon-font: 'foundation-icons';
$cross-icon: '\f217';
$arrow-left-icon: '\f10a';
$arrow-up-icon: '\f10c';

// Ratings
$rating-small-height: 16;
$rating-small-width: $rating-small-height * 5;
$rating-medium-height: 20;
$rating-medium-width: $rating-medium-height * 5;
$rating-large-height: 30;
$rating-large-width: $rating-large-height * 5;

// Misc
$negative-indent: rem-calc(-9999);

// Article List
$panel-background: #F2F2F2;
$panel-border-color: #D7D7D7;

// Gallery font
$gallery-font: 'Roboto Condensed', $body-font;

// Red and Green for tick/cross iconography

$red: #F00;
$dark-red: #C30;
$green: #008000;

//font host name
$font-host-name: #{$static-font-host-name};

//hover button color
$product-card-button-hover-color: #BF6500;

//empty rating star color
$product-card-rating-star-foreground-color: #D7DADB;
